import CoreuiVue from '@coreui/vue'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { createApp } from 'vue'
import { VueFire, VueFireAppCheck, VueFireAuth } from 'vuefire'
import { createHead } from '@unhead/vue'
import { initMixpanel } from '@/services/analyticsService'
import firebaseConfig from '@/services/firebaseConfig'
import {
  AppCheckProvider,
  initializeFirestoreDb,
} from '@/services/firebaseService'
import { initSentry } from '@/services/sentryService'
import { initSmartlook } from '@/services/smartlookService'
import store from './store'
import router from './router'
import App from './App.vue'

export const app = createApp(App)
initMixpanel()
initSentry(app, router)

// Check if Firebase app is already initialized
if (!getApps().length) {
  initializeApp(firebaseConfig)
}

;(async () => { await initSmartlook() })()

// Initialize Firestore

initializeFirestoreDb()

app.use(VueFire, {
  firebaseApp: getApp(),
  modules: [VueFireAppCheck({ provider: AppCheckProvider }), VueFireAuth()],
})

app.use(store)

app.use(router)
const head = createHead()
app.use(head)
app.use(CoreuiVue)

app.mount('#app')
