import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { ReCaptchaV3Provider } from 'firebase/app-check'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { deleteDoc, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getPerformance } from 'firebase/performance'
import firebaseConfig from './firebaseConfig'
import { trackEvent } from '@/services/analyticsService.js'

export const MonitorsPath = 'uptime-monitors'

export const MonitorsCollection = 'monitors'

export const AppCheckProvider = new ReCaptchaV3Provider(
  import.meta.env.VITE_RECAPTCHA_SITE_KEY,
)

// Initialize Firebase app
if (!getApps().length) {
  // console.log('Initializing Firebase app', import.meta.env)
  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)
  setAnalyticsCollectionEnabled(analytics, !import.meta.env.DEV)
  const perf = getPerformance(app)
  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHA_SITE_KEY),
  //   isTokenAutoRefreshEnabled: true
  // })
}

export const analytics = getAnalytics(getApp())

// Initialize Firebase Functions
export const functionsInstance = getFunctions(getApp(), 'europe-west1')

let firestoreDb

export function initializeFirestoreDb() {
  if (getApps().length) {
    firestoreDb = getFirestore(getApp())
  } else {
    throw new Error('Firebase app has not been initialized')
  }
}

export function getFirestoreDb() {
  if (getApps().length) {
    return getFirestore(getApp())
  }
  throw new Error('Firebase app has not been initialized')
}

export async function getFirebaseUser() {
  const auth = getAuth()
  return new Promise(resolve => onAuthStateChanged(auth, resolve))
}

export async function deleteMonitor(monitorId, userUid) {
  try {
    const monitorRef = doc(
      firestoreDb,
      MonitorsPath,
      userUid,
      MonitorsCollection,
      monitorId,
    )
    await deleteDoc(monitorRef)
  } catch (error) {
    console.error('Error deleting monitor: ', error)
  }
}

export async function toggleMonitorIsActive(monitorId, isActive, userUid) {
  try {
    const monitorRef = doc(
      firestoreDb,
      MonitorsPath,
      userUid,
      MonitorsCollection,
      monitorId,
    )
    await updateDoc(monitorRef, { isActive })

    const eventName = isActive ? 'monitor-activated' : 'monitor-deactivated'
    trackEvent(eventName, { distinct_id: userUid, monitorId })
  } catch (error) {
    console.error('Error updating monitor status in Firebase: ', error)
  }
}
