import * as Sentry from '@sentry/vue'

export function initSentry(app, router) {
  if (Sentry.isInitialized()) {
    console.debug('Sentry is already initialized')
    return
  }
  const {
    VITE_SENTRY_DSN: dsn, VITE_SENTRY_ENABLED, VITE_SENTRY_ENVIRONMENT: environment, VITE_DEBUG,
  } = import.meta.env

  const enabled
    = window?.location?.hostname !== 'localhost'
    && VITE_SENTRY_ENABLED === 'true'

  const feedbackButtonLabel = window.innerWidth > 768 ? 'Feedback' : ''
  Sentry.init({
    app,
    dsn,
    enabled,
    environment,
    debug: VITE_DEBUG === 'true',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        enableInp: true,
      }),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        minReplayDuration: 2000,
        unmask: ['.sentry-unmask, [data-sentry-unmask]'],
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.feedbackIntegration({
        showBranding: false,
        buttonLabel: feedbackButtonLabel,
        submitButtonLabel: 'Send Feedback',
        formTitle: 'Send Feedback',
        colorScheme: 'light',
        themeLight: {
          bottom: '1rem',
        },
      }),
    ],
    replaysSessionSampleRate: 1.0, // Be sure to lower this in production
    replaysOnErrorSampleRate: 1.0,
    // Performance Monitoring
    // tracesSampler: ({ transactionContext }) => {
    //   // Check if the transaction is from the function you want to limit
    //   if (
    //     HighFreqTransactionNames.includes(transactionContext.name?.toLowerCase())
    //   ) {
    //     return HighFreqTransactionSampleRate // 10% sampling rate for this function
    //   }
    //   return 1.0 // 100% sampling rate for all other transactions
    // },
    tracesSampleRate: 0.1,
    tracePropagationTargets: [/app.ezuptime.com/],
  })
}

export function hideSentryFeedbackButton() {
  if (document.getElementById('sentry-feedback')) {
    document.getElementById('sentry-feedback').style.display = 'none'
  }
}
