import { getAuth, signOut } from 'firebase/auth'
import { createRouter, createWebHistory } from 'vue-router'
import mixpanel from 'mixpanel-browser'
import { authGuard, sentryFeedbackButton, trackCustomerVisit } from '@/router/guards'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/DefaultLayout.vue'),
    redirect: '/uptime-monitors',
    children: [
      {
        path: '/uptime-monitors',
        name: 'UptimeMonitors',
        component: () => import('@/views/UptimeMonitors.vue'),
      },
      {
        path: '/uptime-monitors/add',
        name: 'UptimeMonitorAdd',
        component: () => import('@/views/UptimeMonitorEdit.vue'),
      },
      {
        path: '/uptime-monitors/:id',
        name: 'UptimeMonitor',
        component: () => import('@/views/UptimeMonitor.vue'),
      },
      {
        path: '/uptime-monitors/:id/edit',
        name: 'UptimeMonitorEdit',
        component: () => import('@/views/UptimeMonitorEdit.vue'),
      },
      {
        path: '/subscriptions',
        name: 'Subscriptions',
        meta: { allowNewCustomers: true },
        component: () => import('@/views/pages/SubscriptionsWayforpay.vue'),
      },
      {
        path: '/notifications',
        name: 'NotificationsOptions',
        meta: { allowNewCustomers: true },
        component: () => import('@/views/NotificationsOptions.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/pages/Profile.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: { allowAnon: true, showFeedback: false },
    redirect: '/auth/login',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { allowAnon: true },
        component: () => import('@/views/pages/Login.vue'),
        props: route => ({
          redirectPath: route.query.redirectPath,
          actionLabel: 'Login',
          loginLabel: 'Click below to enter your account',
        }),
      },
      {
        path: 'signin',
        name: 'Sign-in',
        meta: { allowAnon: true },
        component: () => import('@/views/pages/Login.vue'),
        props: route => ({
          redirectPath: route.query.redirectPath,
          actionLabel: 'Sign in',
          loginLabel: 'Click below to enter your account',
        }),
      },
      {
        path: 'signup',
        name: 'Sign-up',
        meta: { allowAnon: true },
        component: () => import('@/views/pages/Login.vue'),
        props: route => ({
          redirectPath: route.query.redirectPath,
          actionLabel: 'Sign up',
          loginLabel: 'Click below to create new account',
        }),
      },
      {
        path: 'logout',
        name: 'Logout',
        beforeEnter: async (to, from, next) => {
          await signOut(getAuth())
          mixpanel.reset()
          next({ name: 'Login' })
        },
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    meta: { allowAnon: true },
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '404',
        name: 'Page404',
        meta: { allowAnon: true },
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        meta: { allowAnon: true },
        component: () => import('@/views/pages/Page500.vue'),
      },
    ],
  },
  {
    path: '/tools',
    name: 'Tools',
    meta: { allowAnon: true, showFeedback: false },
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/tools/is-it-up',
        name: 'IsItUp',
        meta: { allowAnon: true, showFeedback: false },
        component: () => import('@/views/pages/IsItUp.vue'),
      },
      {
        path: '/tools/user-agent',
        name: 'UserAgent',
        meta: { allowAnon: true, showFeedback: false },
        component: () => import('@/views/pages/UserAgent.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(sentryFeedbackButton)
router.beforeEach(authGuard)
router.beforeEach(trackCustomerVisit)

export default router
