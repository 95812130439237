const hostname =
  typeof window !== 'undefined' ? window.location.hostname : 'default-hostname'

const authDomain =
  hostname === 'app.ezuptime.com'
    ? 'app.ezuptime.com'
    : 'vigilant-5d715.firebaseapp.com'
console.debug('authDomain', authDomain)

const firebaseConfig = {
  apiKey: 'AIzaSyDHkraFhhF-_PgGwM-zPE2zM2qANi0ltRM',
  appId: '1:116872122023:web:fa8ee92b8369b7fc7cd582',
  authDomain,
  databaseURL:
    'https://vigilant-5d715-default-rtdb.europe-west1.firebasedatabase.app',
  measurementId: 'G-CY93WLDFCV',
  messagingSenderId: '116872122023',
  projectId: 'vigilant-5d715',
  storageBucket: 'vigilant-5d715.appspot.com',
}

export default firebaseConfig
