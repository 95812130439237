import Smartlook from 'smartlook-client'
import { getFirebaseUser } from '@/services/firebaseService'

export function identifyUser(user) {
  if (user) {
    Smartlook.identify(user.uid, {
      email: user.email,
      name: user.displayName,
    })
    console.debug('Smartlook user identified as ', user.uid)
  }
}

export async function initSmartlook() {
  if (!window?.location.hostname.includes('ezuptime.com')) {
    console.debug(`Not initializing Smartlook. Host is ${window?.location.hostname}.`)
    return
  }
  const {
    MODE,
    VITE_SMARTLOOK_ENABLED,
    VITE_SMARTLOOK_KEY,
    VITE_SMARTLOOK_REGION: region = 'eu',
    VITE_SMARTLOOK_RELAY_PROXY_URL: relayProxyUrl,
  } = import.meta.env

  if (MODE !== 'production' || VITE_SMARTLOOK_ENABLED !== 'true') {
    console.debug('Smartlook disabled', { MODE, VITE_SMARTLOOK_ENABLED })
    return
  }

  console.debug('Initializing Smartlook')
  Smartlook.init(VITE_SMARTLOOK_KEY, { region, relayProxyUrl })
  Smartlook.record({ forms: true, ips: true, numbers: true })
  const user = await getFirebaseUser()
  identifyUser(user)
  // console.log('Smartlook enabled', VITE_SMARTLOOK_KEY, user)
  // TODO: integrate with Mixpanel - see https://integrations.smartlook.com/docs/mobile-integrations-mixpanel
}
