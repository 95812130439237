import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { getFirebaseUser, getFirestoreDb } from '@/services/firebaseService'
import { hideSentryFeedbackButton } from '@/services/sentryService.js'

export async function authGuard(to, from) {
  const user = await getFirebaseUser()
  if (
    user
    || to.meta?.allowAnon
    || ['Login', 'Sign-in', 'Sign-up'].includes(to.name)
  ) {
    return true
  }
  if (!to.fullPath.startsWith('/auth')) {
    sessionStorage.setItem('redirectPath', to.fullPath)
  }
  return { name: 'Login' }
}

export async function trackCustomerVisit(to, from) {
  const user = await getFirebaseUser()

  if (!user) {
    return true
  }
  const firestoreDb = await getFirestoreDb()
  const customerRef = await doc(firestoreDb, 'customers', user.uid)
  if (!(await getDoc(customerRef)).exists()) {
    return true
  }
  await updateDoc(customerRef, {
    'lastActivity.app-visit': serverTimestamp(),
  })
  return true
}

export function sentryFeedbackButton(to) {
  if (to.meta?.showFeedback === false) {
    hideSentryFeedbackButton()
  }
  return true
}
