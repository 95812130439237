import { logEvent } from 'firebase/analytics'
import mixpanel from 'mixpanel-browser'
import { analytics } from '@/services/firebaseService'

const ProductionHost = 'app.ezuptime.com'

export function initMixpanel() {
  const {
    VITE_MIXPANEL_ENABLED,
    VITE_MIXPANEL_TOKEN,
    VITE_MIXPANEL_TOKEN_DEV,
    VITE_MIXPANEL_DEBUG,
    VITE_MIXPANEL_HOST,
  } = import.meta.env
  let token = VITE_MIXPANEL_TOKEN
  if (window.location.hostname.toLowerCase().search(ProductionHost) < 0) {
    token = VITE_MIXPANEL_TOKEN_DEV
  }
  if (VITE_MIXPANEL_ENABLED === 'true') {
    mixpanel.init(token, {
      api_host: VITE_MIXPANEL_HOST,
      debug: VITE_MIXPANEL_DEBUG,
      track_pageview: true,
    })
  }
}

export function trackConversionEvent(eventName, eventParams = {}) {
  logEvent(analytics, eventName, eventParams)
  mixpanel.track(eventName, eventParams)
}

export const trackEvent = trackConversionEvent

export function trackCustomerPlanChange(planSlug, planPrice) {
  mixpanel.people.set({ planSlug, planPrice })
}
